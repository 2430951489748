$section-class: "nk-section";

%shape-bg {
  content: "";
  z-index: -1;
  opacity: 0.6;
  user-select: none;
  position: absolute;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.nk-section[class^="bg-"] {
  display: none;
}

// Section Heading
.#{$section-class} {
  z-index: 1;
  position: relative;

  // Section Block Spacing
  padding: 30px 0px;

  @include media-breakpoint-up(lg) {
    padding: 60px 0px;
  }

  &-head {
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-bottom: 60px;
    }
  }
}

// Section Space Controll
.#{$section-class} {
  &-space {
    padding: 30px 0px;

    @include media-breakpoint-up(sm) {
      padding: 60px 0px;
    }

    &-lg {
      padding: 30px 0px;
      @include media-breakpoint-up(sm) {
        padding: 60px 0px;
      }
      @include media-breakpoint-up(lg) {
        padding: 120px 0px;
      }
    }
  }
}

.#{$section-class} {
  // business solution - Features 2 page
  &-tools {
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  &-integrate {
    .nk-block-head-content {
      padding: 60px 0px;
    }
  }

  &-teams {
    .#{$section-class} {
      &-content {
        .swiper {
          &-slide {
            transform: scale(0.8);
            transition: all 0.3s linear;

            img {
              transition: all 0.3s ease-in;
              filter: grayscale(100%) blur(1px);
              -webkit-filter: grayscale(100%) blur(1px);
            }

            &-active {
              transform: scale(1);

              img {
                filter: grayscale(0%) blur(0px);
                -webkit-filter: grayscale(0%) blur(0px);
              }
            }
          }

          &-pagination {
            margin: 0px auto;
            position: static;
            padding-top: 30px;
            transform: translateX(0);

            @include media-breakpoint-up(md) {
              padding-top: 60px;
            }

            &-bullet {
              transition: all 0.2s linear;
              &-active {
                width: 24px;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }

  // Layouts

  &-info-about {
    .nk-frame {
      &-children {
        display: none;
        @include media-breakpoint-up(md) {
          bottom: 0;
          left: -30px;
          display: block;
        }

        @include media-breakpoint-up(lg) {
          bottom: -30px;
        }
      }
    }
  }

  &-job-details {
    @include media-breakpoint-up(lg) {
      margin-top: -280px;
    }

    .#{$section-class} {
      &-wrap {
        @include media-breakpoint-down(md) {
          padding-top: 120px;
        }
      }

      &-content {
        @include media-breakpoint-up(lg) {
          border-radius: 16px;
          background-color: $white;
          border: 1px solid $hr-border-color;
        }
      }
    }

    .nk-entry {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid $hr-border-color;
      }
    }
  }

  // Shops
  &-products {
    .#{$section-class} {
      &-content {
        &-sidebar {
          @include media-breakpoint-up(lg) {
            left: 0;
            top: 120px;
            position: sticky;
          }
        }
      }
    }
  }

  &-product-details {
    z-index: 1;
    position: relative;

    &::before {
      content: "";
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("#{$coreRoot}inside-pages/bg/product-details-bg.svg");
    }

    .nk-product {
      &-specification {
        gap: 14px;
        display: flex;
        flex-direction: column;
        &-content {
          gap: 50px;
          display: flex;
          max-width: 300px;
          align-items: center;
          justify-content: space-between;

          .color-select {
            gap: 10px;
            display: flex;
            align-items: center;
            .form-check-input {
              width: 18px;
              height: 18px;

              &:checked {
                box-shadow: none;
                background: none;
                transition: all 0.2s ease-in-out;
              }

              &.almond {
                border-color: transparent;
                background-color: #eed5c1;

                &:checked {
                  border-color: #faf3ed;
                }
              }
              &.steel-blue {
                border-color: transparent;

                // background-color: #64728f;
                background-color: #eabb40;

                &:checked {
                  border-color: #d1d5de;
                }
              }

              &.electric-blue {
                border-color: transparent;
                background-color: #13d5ff;

                &:checked {
                  border-color: #b9f3ff;
                }
              }

              &.dark-olive {
                border-color: transparent;
                background-color: #49430b;

                &:checked {
                  border-color: #c9c7b6;
                }
              }
            }
          }
        }
      }
    }

    .nk-social {
      & > ul {
        gap: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 50%;
            top: 50%;
            right: -7px;
            position: absolute;
            transform: translateY(-50%);
            background-color: #64728f;
          }

          &:nth-last-child(1) {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .nk-nav-tabs {
      .nav-tabs {
        gap: 12px;
        border-bottom: 1px solid $border-color;

        @include media-breakpoint-up(md) {
          gap: 20px;
        }

        .nav-link {
          border: 0;
          padding: 0px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.14;
          border-radius: 0px;
          color: #64728f;
          padding-bottom: 12px;
          text-transform: uppercase;
          transition: all 0.3s linear;
          border-bottom: 2px solid transparent;

          @include media-breakpoint-up(md) {
            padding-bottom: 24px;
          }

          &.active {
            color: #0a1425;
            border-color: $primary;
          }
        }
      }
    }

    .swiper {
      &.product-slider-lg {
        .swiper-slide {
          overflow: hidden;
          position: relative;
          border-radius: 10px;
        }
      }
      &.product-slider-sm {
        .swiper-slide {
          overflow: hidden;
          position: relative;
          border-radius: 4px;
          transition: all 0.3s linear;
          border: 2px solid transparent;

          &-active {
            border-color: $primary;
          }
        }
      }

      &-button-next,
      &-button-prev {
        opacity: 0.6;
        transition: all 0.3s linear;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  // kids course gallery
  &-gallery {
    .nk-mask {
      z-index: -1;
    }
  }
}

.careers {
  .#{$section-class} {
    &-career-details {
      .#{$section-class} {
        &-blog-details {
          ul {
            li {
              p {
                &:nth-child(1) {
                  width: 30%;
                }
                &:last-child(1) {
                  width: 70%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.layout {
  // App Page
  &-5 {
    .#{$section-class} {
      &-support {
        &::after {
          @extend %shape-bg;
          right: 0;
          top: -60px;
          width: 36.9%;
          height: calc(100% + 60px);
          border-top-left-radius: 240px;
          background-color: #e3f1ef;
        }

        .nk-mask {
          &.shape {
            &::after,
            &::before {
              content: "";
              z-index: -1;
              opacity: 0.6;
              user-select: none;
              position: absolute;
              pointer-events: none;
              background-size: cover;
              background-repeat: no-repeat;
            }

            &::before {
              @include media-breakpoint-up(lg) {
                top: 0;
                left: 0;
                width: 266px;
                height: 449px;
                background-size: contain;
                background-image: url("#{$coreRoot}inside-pages/objects/object-2.png");
              }
            }

            &::after {
              @include media-breakpoint-up(lg) {
                right: 0;
                bottom: 0;
                width: 330px;
                height: 330px;
                background-size: contain;
                background-image: url("#{$coreRoot}inside-pages/objects/object-1.png");
              }
            }
          }
        }
      }

      &-cta {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          position: absolute;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("#{$coreRoot}inside-pages/bg/live-chat-cta-bg.png");
        }
      }
    }
  }

  &-6 {
    .#{$section-class} {
      &-content {
        .nk-mask {
          z-index: 1;

          &.shape {
            &::before,
            &::after {
              @extend %shape-bg;
              top: 0;
              z-index: 1;
              height: 100%;
              width: 558px;
              background-position: center;
            }

            &::before {
              left: 0;
              background-image: url("#{$coreRoot}shape/y.png");
            }

            &::after {
              right: 0;
              background-image: url("#{$coreRoot}shape/z.png");
            }
          }
        }
      }
    }
  }

  &-10 {
    .#{$section-class} {
      &-player {
        @include media-breakpoint-down(md) {
          padding-bottom: 30px;
        }

        .nk-frame {
          z-index: 1;

          &-children {
            @include media-breakpoint-down(md) {
              display: none;
            }
            &-one {
              top: -50px;
              left: -45px;
            }

            &-two {
              right: 0px;
              z-index: -1;
              bottom: -45px;
              @include media-breakpoint-up(md) {
                right: -50px;
              }
            }
          }
        }

        .nk-video {
          height: auto;
          margin-top: 60px;

          @include media-breakpoint-up(lg) {
            margin-top: -130px;
          }

          &-img {
            width: 100%;
          }

          &-counter {
            margin-top: 30px;

            @include media-breakpoint-up(md) {
              z-index: 6;
              width: 80%;
              margin: 0px auto;
              margin-top: -80px;
              position: relative;
            }

            @include media-breakpoint-up(lg) {
              width: 95%;
            }

            @include media-breakpoint-up(xl) {
              width: 928px;
            }
          }
        }
      }
    }

    // swiper
    .nk-testimonial-card-s3 .nk-testimonial-icon .icon {
      background: linear-gradient(180deg, $info-alt 0%, $info 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-11 {
    .form-check {
      input {
        &:checked {
          border-color: $primary-alt;
          background-color: $primary-alt;
        }
      }
    }
  }

  &-13 {
    .#{$section-class} {
      &-cta {
        &.bg-2::after {
          background-color: $info-alt;
        }
      }
    }
  }
}

//  responsive breakpoints
@include media-breakpoint-up(lg) {
  .#{$section-class} {
    &-blog {
      &-sidebar {
        top: 120px;
        left: 0px;
        position: sticky;
      }
    }

    &-terms {
      .nk-entry {
        &-sidebar {
          top: 120px;
          left: 0px;
          position: sticky;
        }
      }
    }

    &-integrate {
      .nk-block-head-content {
        padding: 120px 0px;
      }

      &::before {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .#{$section-class} {
    &-integrate {
      .nk-block-head-content {
        padding: 245px 0px;
      }
    }
  }
}
